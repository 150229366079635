import { createSlice } from "@reduxjs/toolkit";

const profileInitialState = { profileData: null };

const profileSlice = createSlice({
  name: "profile",
  initialState: profileInitialState,
  reducers: {
    setProfileData(state, action) {
      state.profileData = action.payload;
    },
    resetProfileData(state) {
      state.profileData = null;
    },
  },
});

const profileActions = profileSlice.actions;
const profileReducer = profileSlice.reducer;

export { profileActions, profileReducer };
