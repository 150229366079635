import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { logout as logoutAction } from "../store/actions/user";

export const useCheckTokenExpiration = () => {
  const dispatch = useDispatch();
  const userState = useSelector((state) => state.user);
  const accessTokenExpiredAt = userState?.userInfo?.accessTokenExpiredAt;

  useEffect(() => {
    if (accessTokenExpiredAt) {
      const checkExpiration = () => {
        const currentTime = Date.now();
        const expiredTime = parseInt(accessTokenExpiredAt);
        if (currentTime >= expiredTime) {
          dispatch(logoutAction()); // nếu expired thì token không còn đúng nữa. xóa account ở localStorage là được
        }
      };

      const interval = setInterval(checkExpiration, 1000);

      return () => clearInterval(interval);
    }
  }, [accessTokenExpiredAt, dispatch]);
};
