import { configureStore } from "@reduxjs/toolkit";

import { userReducer } from "./reducers/userReducers";
import { profileReducer } from "./reducers/profileReducers";

const userInfoFromStorage = localStorage.getItem("account")
  ? JSON.parse(localStorage.getItem("account"))
  : null;

const initialState = {
  user: { userInfo: userInfoFromStorage },
  profile: { profileData: null },
};

const store = configureStore({
  reducer: {
    user: userReducer,
    profile: profileReducer,
  },
  preloadedState: initialState,
});

export default store;
