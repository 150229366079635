import React from "react";
import RotateLoader from "react-spinners/RotateLoader";
import BarLoader from "react-spinners/BarLoader";
import BeatLoader from "react-spinners/BeatLoader";
import ClipLoader from "react-spinners/ClipLoader";

const Spinner = ({ name, loading, color = "#000", size = 15 }) => {
  let LoaderComponent;
  if (name === "BarLoader") {
    LoaderComponent = BarLoader;
  } else if (name === "BeatLoader") {
    LoaderComponent = BeatLoader;
  } else if (name === "ClipLoader") {
    LoaderComponent = ClipLoader;
  } else {
    LoaderComponent = RotateLoader;
  }

  return (
    <span className="inline-block">
      <LoaderComponent color={color} loading={loading} size={size} />
    </span>
  );
};

export default Spinner;
