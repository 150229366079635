import React, { lazy, Suspense } from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import ProtectedRoute from "./ProtectedRoute";
import Spinner from "../components/UI/Spinner";

const HomePage = lazy(() => import("../pages/home/HomePage"));
const LoginPage = lazy(() => import("../pages/login/LoginPage"));
const ForgotPasswordPage = lazy(
  () => import("../pages/auth/ForgotPasswordPage")
);
const NewPasswordPage = lazy(() => import("../pages/auth/NewPasswordPage"));
const AlbumPage = lazy(() => import("../pages/album/AlbumPage"));
const PressAndAwards = lazy(() => import("../pages/paa/PressAndAwards"));
const ContactPage = lazy(() => import("../pages/contact/ContactPage"));
const ProfilePage = lazy(() => import("../pages/profile/ProfilePage.jsx"));
const ProfileAlbumPage = lazy(
  () => import("../pages/profile/ProfileAlbumPage.jsx")
);

const AppRoutes = () => {
  return (
    <Suspense
      fallback={
        <div className="w-full h-screen flex justify-center items-center">
          <Spinner />
        </div>
      }
    >
      <Routes>
        {/* Các routes không cần xác thực */}
        <Route path="/" element={<HomePage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/forgot-password" element={<ForgotPasswordPage />} />
        <Route path="/new-password" element={<NewPasswordPage />} />
        <Route path="/album/:slug" element={<AlbumPage />} />
        <Route path="/press-and-awards" element={<PressAndAwards />} />
        <Route path="/contact" element={<ContactPage />} />

        {/* Các routes cần xác thực */}
        <Route
          path="/profile"
          element={<ProtectedRoute element={ProfilePage} />}
        />
        <Route
          path="/profile/album"
          element={<ProtectedRoute element={ProfileAlbumPage} />}
        />
        {/* Route mặc định */}
        <Route path="*" element={<Navigate replace to="/" />} />
      </Routes>
    </Suspense>
  );
};

export default AppRoutes;
