import React from "react";
import { Toaster } from "react-hot-toast";

import "./App.css";
import AppRoutes from "./routes/AppRoutes";
import { useCheckTokenExpiration } from "./hooks/useCheckTokenExpiration";

function App() {
  useCheckTokenExpiration();

  return (
    <div className="App font-opensans">
      <AppRoutes />
      <Toaster />
    </div>
  );
}

export default App;
