import React from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

const ProtectedRoute = ({ element: Component, ...rest }) => {
  const userState = useSelector((state) => state.user);
  const isAuth = Boolean(userState?.userInfo);

  return isAuth ? <Component {...rest} /> : <Navigate to="/login" />;
};

export default ProtectedRoute;
